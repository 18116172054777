const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        dataContact: null,
    }),

    getters: {
        Contact: (state) => state.dataContact,
    },

    actions: {
        req_postSendContact({ commit }, input) {
            axios.post('contact-info', input).then((res) => {
                commit('SEND_CONTACT_SUCCESS', res);
            });
        },
    },

    mutations: {
        SEND_CONTACT_SUCCESS: () => {},
    },
};
