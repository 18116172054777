// const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        error: '',
        success: '',
    }),

    getters: {
        Error: (state) => state.error,
        Success: (state) => state.success,
        typeAccount: (state) => state.type_account,
    },

    actions: {},
    mutations: {
        SUCCESS_MESSAGE(state, message) {
            state.success = message;
        },
        ERROR_MESSAGE(state, message) {
            state.error = message;
        },
        ERROR_500(state, message) {
            state.status = message;
        },
    },
};
