import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

let first = true;

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            // This ensures that if hash is provided to router.push it works as expected.
            //  & since we have used "behavior: 'smooth'" the browser will slowly come to this hash position.
            return {
                el: to.hash,
                behavior: 'smooth',
            };
        }

        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
    linkActiveClass: 'active',
    linkExactActiveClass: 'active-children',
});

router.beforeEach(async (to, from, next) => {
    const { type, id } = to.params;
    const { name } = to;
    if (type && first && !id) {
        if (
            type.slice(0, 3).toLocaleLowerCase() !==
            name.slice(0, 3).toLocaleLowerCase()
        ) {
            next('/home');
            first = false;
        }
        next();
    } else {
        next();
    }
});

export default router;
