import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueSlickCarousel from 'vue-slick-carousel';
import VueToastr from 'vue-toastr';
import Vuelidate from 'vuelidate';
import moment from 'moment';
import * as VueGoogleMaps from 'vue2-google-maps';
import AOS from 'aos';
import App from './App.vue';
import router from './router';
import store from './store';
import 'aos/dist/aos.css';

// theme and bootstrap package
import './design/scss/app.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
// vue slick carousel
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
// import fontawesome
import '@/assets/font-awesome/css/duotone.min.css';
import '@/assets/font-awesome/css/fontawesome.min.css';
import '@/assets/font-awesome/css/solid.min.css';
import '@/assets/font-awesome/css/regular.min.css';

import '@/assets/font-awesome/js/fontawesome';
import '@/assets/font-awesome/js/solid';
import '@/assets/font-awesome/js/duotone';
import '@/assets/font-awesome/js/regular';
import i18n from './i18n';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueSlickCarousel);
Vue.use(Vuelidate);

Vue.use(VueToastr, {
    defaultTimeout: 2000,
    defaultPosition: 'toast-top-right',
    defaultClassNames: ['animate__animated ', 'animate__bounceInRight'],
});

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyA8F7vFQ_-FA3KkmHggZDvdzGBk7YEkkY8',
        libraries: 'places',
    },
});

Vue.mixin({
    methods: {
        getDateTime(unix) {
            moment.locale('vi');
            return moment(unix).utcOffset('+00:00').format('DD/MM/YYYY');
        },
    },
});

Vue.config.productionTip = false;

new Vue({
    router,
    created() {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: true, // if true, will add content of `data-aos` as classes on scroll
            disableMutationObserver: false, // disables automatic mutations' detections (advanced)
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 100, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 2500, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: true, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regardi
        });
    },
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
