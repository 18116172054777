<template>
    <footer class="footer-area footer--light py-2">
        <div class="footer-big">
            <div class="row py-2">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="footer-widget">
                        <div class="widget-logo d-flex align-items-center mb-3">
                            <img
                                :src="
                                    require('@/assets/images/logo/navlogo1.png')
                                "
                                alt="Logo"
                                class="img-fluid"
                                style="height: 100px"
                            />
                        </div>
                        <div class="widget-about footer-menu--3">
                            <ul>
                                <li>
                                    <p>
                                        {{ $t('footer.about') }}
                                    </p>
                                </li>
                                <li>
                                    <p>PI SOLUTION</p>
                                </li>
                                <li>
                                    <p>{{ $t('footer.slogan') }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="footer-widget">
                        <div class="footer-menu footer-menu--1">
                            <p class="footer-widget-title">
                                {{ $t('footer.contact') }}:
                            </p>
                            <div class="widget-contact">
                                <ul>
                                    <li>
                                        <p>
                                            {{ $t('footer.address') }}
                                        </p>
                                        <p>
                                            {{ $t('footer.address2') }}
                                        </p>
                                    </li>
                                    <li>
                                        <a href="mailto:CUSTOMER@PISOLUTION.CO">
                                            <p>Email: CUSTOMER@PISOLUTION.CO</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#companies">
                                            <p>Telegram: @Pisolution</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/profile.php?id=100087183701311"
                                            target="_blank"
                                        >
                                            <p>Fanpage: Pi Solution</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.youtube.com/@picreative4317"
                                            target="_blank"
                                            ><p>Youtube: Pi Solution</p></a
                                        >
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="footer-widget">
                        <div class="footer-menu footer-menu--2">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.9739899712548!2d106.70045260278337!3d10.766521543188766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752fd917f30a61%3A0xf796109fa85ad35f!2sPi%20Gaming%20%26%20Coffe!5e0!3m2!1svi!2s!4v1680492710231!5m2!1svi!2s"
                                width="400"
                                height="300"
                                style="border: 0"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterSection',
};
</script>

<style lang="scss" scoped>
ul {
    padding: 0;
    margin: 0;
}
li {
    list-style: none;
}
a:focus,
a:hover {
    text-decoration: none;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
}
a:focus {
    outline: 0;
}
img {
    max-width: 100%;
}
p {
    font-size: 1.2 + -rem;
    line-height: 30px;
    color: #fafafa;
    font-weight: 300;
}
h4 {
    font-family: Rubik, sans-serif;
    margin: 0;
    font-weight: 400;
    padding: 0;
    color: #ffffff;
}

h3 {
    font-family: Rubik, sans-serif;
    font-weight: 400;
    padding: 0;
    color: #ffffff;
    margin-top: 3rem;
}
a {
    color: #ffffff;
}

.footer-big {
    padding: 30px 0 10px 30px;
}
.footer-big .footer-widget {
    margin-bottom: 40px;
}
.footer--light {
    background-image: url('../../assets/images/footer/bg-img.png');
    background-size: cover;
    background-position: 0 10%;
    background-repeat: no-repeat;
    padding: 10px 20px;
}
.footer-big .footer-menu ul li a,
.footer-big p,
.footer-big ul li {
    color: #ffffff;
}

.footer-menu ul li a {
    font-size: 1.2rem;
    line-height: 32px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.footer-menu ul li a:hover {
    font-weight: bolder;
}
.footer-menu--1 {
    width: 100%;
    text-align: left;
}

.footer-menu--2 {
    width: 100%;
    text-align: center;
}

@media (max-width: 768px) {
    .footer-menu--3 ul li p {
        font-size: 1rem;
    }

    .footer-menu--1 ul li p {
        font-size: 1rem;
    }
    .widget-logo {
        justify-content: center;
    }
}

.footer-menu--1 ul li a {
    font-size: 0.9rem;
}

.footer-widget-title {
    font-size: 1.8rem;
    @media screen and (max-width: 768px) {
        font-size: 1.3rem;
    }
    text-align: left;
    text-transform: uppercase;
}

.widget-about ul {
    text-align: left;
    margin-top: 2rem;
    p {
        font-weight: 400;
        font-size: 1.2rem;
    }
}

.widget-contact ul {
    text-align: left;
    margin-top: 2rem;
    li {
        margin-bottom: 1.5rem;
    }
    p {
        font-weight: 400;
        font-size: 1.2rem;
    }
}

.map {
    width: 80%;
}

@media (max-width: 991px) {
    .footer-menu {
        padding-left: 0;
    }
}
</style>
