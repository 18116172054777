const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        listNews: null,
        listBlog: null,
        newsDetails: null,
    }),

    getters: {
        News: (state) => state.listNews,
        Blogs: (state) => state.listBlog,
        NewsDetails: (state) => state.newsDetails,
    },

    actions: {
        req_getListNews({ commit }, input) {
            axios.get('article', { params: input }).then((res) => {
                if (res) {
                    commit('SET_LIST_NEWS', res);
                }
            });
        },
        req_getListBlog({ commit }) {
            axios.get('blog').then((res) => {
                if (res) {
                    commit('SET_LIST_BLOG', res);
                }
            });
        },
        req_getNewsDetails({ commit }, input) {
            axios.get(`article/detail?slug=${input}`).then((res) => {
                if (res) {
                    commit('SET_NEWS_DETAILS', res);
                }
            });
        },
        req_getNewsByBlogType({ commit }, input) {
            axios.get(`article/blog?slug=${input}`).then((res) => {
                if (res) {
                    commit('SET_NEWS_BY_BLOG_TYPE', res);
                }
            });
        },
    },

    mutations: {
        SET_LIST_NEWS(state, data) {
            console.log(data);
            state.listNews = data;
        },
        SET_LIST_BLOG(state, data) {
            state.listBlog = data;
        },
        SET_NEWS_DETAILS(state, data) {
            state.newsDetails = data;
        },
        SET_NEWS_BY_BLOG_TYPE(state, data) {
            state.listNews = data;
        },
    },
};
