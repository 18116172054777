var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:{ sticky: _vm.sticky },attrs:{"id":"top-bar"}},[_c('b-navbar',{staticClass:"bg-nav",attrs:{"toggleable":"lg"}},[_c('b-navbar-brand',{attrs:{"href":"/"}},[_c('img',{staticClass:"navlogo",attrs:{"src":require('@/assets/images/logo/navlogo1.png'),"alt":"navlogo"}})]),_c('b-navbar-toggle',{staticClass:"custom-toggler",attrs:{"target":"sidebar"}}),_c('b-sidebar',{attrs:{"id":"sidebar"}},[_c('b-navbar-nav',{staticClass:"ml-auto",class:_vm.active,on:{"click":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"navlogo-sidebar",attrs:{"href":""}},[_c('img',{staticClass:"logo-sidebar",attrs:{"src":require('@/assets/images/logo/navlogo1.png'),"alt":"navlogo"}})]),_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                        name: 'Home',
                        params: {
                            type: 'home',
                        },
                    }}},[_c('div',{staticClass:"nav-item home"},[_vm._v(_vm._s(_vm.$t('navbar.home')))])]),_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                        name: 'About',
                        params: {
                            type: 'about',
                        },
                    }}},[_c('div',{staticClass:"nav-item about"},[_vm._v(" "+_vm._s(_vm.$t('navbar.about'))+" ")])]),_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                        name: 'Brand',
                        params: {
                            type: 'brand',
                        },
                    }}},[_c('div',{staticClass:"nav-item brand"},[_vm._v(" "+_vm._s(_vm.$t('navbar.brand'))+" ")])]),_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                        name: 'Project',
                        params: {
                            type: 'project',
                        },
                    }}},[_c('div',{staticClass:"nav-item project"},[_vm._v(" "+_vm._s(_vm.$t('navbar.project'))+" ")])]),_c('div',{staticClass:"sub-news-nav"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                            name: 'News',
                            params: {
                                type: 'news',
                            },
                        }}},[_c('div',{staticClass:"nav-item news"},[_vm._v(" "+_vm._s(_vm.$t('navbar.news'))+" ")])]),_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse",modifiers:{"collapse":true}}],on:{"click":_vm.handleCollapse}},[_c('div',{ref:"iconCollapse",staticClass:"icon-collapse"},[_c('i',{staticClass:"fas fa-angle-left"})])])],1),_c('b-collapse',{attrs:{"id":"collapse"}},[_c('div',{staticClass:"news-expand"},[_c('router-link',{attrs:{"to":{
                                name: 'News',
                                query: {
                                    loai: 'noi-bat',
                                },
                            }}},[_c('div',{staticClass:"nav-item news"},[_vm._v(" "+_vm._s(_vm.$t('navbar.outstanding'))+" ")])]),_c('div',{staticClass:"dropdown-divider"}),_c('router-link',{attrs:{"to":{
                                name: 'News',
                                query: {
                                    loai: 'moi',
                                },
                            }}},[_c('div',{staticClass:"nav-item news"},[_vm._v(" "+_vm._s(_vm.$t('navbar.newest'))+" ")])])],1)]),_c('b-nav-item-dropdown',{staticClass:"dropdown-sidebar",attrs:{"text":_vm.$t('navbar.career')}},[_c('b-dropdown-item',[_c('router-link',{attrs:{"to":{
                                name: 'Careers',
                                params: {
                                    type: 'careers',
                                },
                            }}},[_c('div',{staticClass:"nav-item careers"},[_vm._v(" "+_vm._s(_vm.$t('navbar.opportunity'))+" ")])])],1),_c('div',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',[_c('router-link',{attrs:{"to":{
                                name: 'LifeofPi',
                                params: {
                                    type: 'lifeofpi',
                                },
                            }}},[_c('div',{staticClass:"nav-item lop"},[_vm._v(" Life of Pi ")])])],1)],1),_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                        name: 'Contact',
                        params: {
                            type: 'contact',
                        },
                    }}},[_c('div',{staticClass:"nav-item contact"},[_vm._v(" "+_vm._s(_vm.$t('navbar.contact'))+" ")])])],1)],1),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"nav-wrapper",on:{"click":function($event){$event.preventDefault();}}},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                        name: 'Home',
                        params: {
                            type: 'home',
                        },
                    }}},[_c('div',{staticClass:"nav-item home"},[_vm._v(_vm._s(_vm.$t('navbar.home')))])]),_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                        name: 'About',
                        params: {
                            type: 'about',
                        },
                    }}},[_c('div',{staticClass:"nav-item about"},[_vm._v(" "+_vm._s(_vm.$t('navbar.about'))+" ")])]),_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                        name: 'Brand',
                        params: {
                            type: 'brand',
                        },
                    }}},[_c('div',{staticClass:"nav-item brand"},[_vm._v(" "+_vm._s(_vm.$t('navbar.brand'))+" ")])]),_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                        name: 'Project',
                        params: {
                            type: 'project',
                        },
                    }}},[_c('div',{staticClass:"nav-item project"},[_vm._v(" "+_vm._s(_vm.$t('navbar.project'))+" ")])]),_c('div',{staticClass:"nav-link-item news"},[_c('router-link',{staticClass:"nav-item-news",attrs:{"to":{
                            name: 'News',
                            params: {
                                type: 'news',
                            },
                        }}},[_c('span',[_vm._v(_vm._s(_vm.$t('navbar.news')))])]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
                                    name: 'News',
                                    query: {
                                        loai: 'noi-bat',
                                    },
                                }}},[_vm._v(_vm._s(_vm.$t('navbar.outstanding')))])],1),_c('li',[_c('router-link',{attrs:{"to":{
                                    name: 'News',
                                    query: {
                                        loai: 'moi',
                                    },
                                }}},[_vm._v(_vm._s(_vm.$t('navbar.newest')))])],1)])],1),_c('b-nav-item-dropdown',{staticClass:"nav-link-item",attrs:{"text":_vm.$t('navbar.career')}},[_c('b-dropdown-item',[_c('router-link',{attrs:{"to":{
                                name: 'Careers',
                                params: {
                                    type: 'careers',
                                },
                            }}},[_c('div',{staticClass:"nav-item careers"},[_vm._v(" "+_vm._s(_vm.$t('navbar.opportunity'))+" ")])])],1),_c('div',{staticClass:"dropdown-divider"}),_c('b-dropdown-item',[_c('router-link',{attrs:{"to":{
                                name: 'LifeofPi',
                                params: {
                                    type: 'lifeofpi',
                                },
                            }}},[_c('div',{staticClass:"nav-item lop"},[_vm._v(" Life of Pi ")])])],1)],1),_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                        name: 'Contact',
                        params: {
                            type: 'contact',
                        },
                    }}},[_c('div',{staticClass:"nav-item contact"},[_vm._v(" "+_vm._s(_vm.$t('navbar.contact'))+" ")])])],1),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-dropdown',{staticClass:"btn",attrs:{"variant":"white","right":"","toggle-class":"lang-item"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.languageActive.title)+" ")]},proxy:true}])},_vm._l((_vm.languages),function(lang,i){return _c('b-dropdown-item',{key:("Lang" + i),staticClass:"language-item",class:{
                            active:
                                _vm.languageActive.language === lang.language,
                        },attrs:{"value":lang},on:{"click":function($event){return _vm.setLanguage(lang.language, lang.title)}}},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(lang.title))])])}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }