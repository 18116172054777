<template>
    <header id="top-bar" :class="{ sticky: sticky }">
        <b-navbar toggleable="lg" class="bg-nav">
            <b-navbar-brand href="/">
                <img
                    :src="require('@/assets/images/logo/navlogo1.png')"
                    alt="navlogo"
                    class="navlogo"
                />
            </b-navbar-brand>
            <b-navbar-toggle
                class="custom-toggler"
                target="sidebar"
            ></b-navbar-toggle>
            <b-sidebar id="sidebar">
                <b-navbar-nav
                    class="ml-auto"
                    v-bind:class="active"
                    v-on:click.prevent
                >
                    <div class="navlogo-sidebar" href="">
                        <img
                            :src="require('@/assets/images/logo/navlogo1.png')"
                            alt="navlogo"
                            class="logo-sidebar"
                        />
                    </div>
                    <router-link
                        :to="{
                            name: 'Home',
                            params: {
                                type: 'home',
                            },
                        }"
                        class="nav-link-item"
                    >
                        <div class="nav-item home">{{ $t('navbar.home') }}</div>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'About',
                            params: {
                                type: 'about',
                            },
                        }"
                        class="nav-link-item"
                    >
                        <div class="nav-item about">
                            {{ $t('navbar.about') }}
                        </div>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'Brand',
                            params: {
                                type: 'brand',
                            },
                        }"
                        class="nav-link-item"
                    >
                        <div class="nav-item brand">
                            {{ $t('navbar.brand') }}
                        </div>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'Project',
                            params: {
                                type: 'project',
                            },
                        }"
                        class="nav-link-item"
                    >
                        <div class="nav-item project">
                            {{ $t('navbar.project') }}
                        </div>
                    </router-link>
                    <div class="sub-news-nav">
                        <router-link
                            :to="{
                                name: 'News',
                                params: {
                                    type: 'news',
                                },
                            }"
                            class="nav-link-item"
                        >
                            <div class="nav-item news">
                                {{ $t('navbar.news') }}
                            </div>
                        </router-link>
                        <button v-b-toggle.collapse @click="handleCollapse">
                            <div class="icon-collapse" ref="iconCollapse">
                                <i class="fas fa-angle-left"></i>
                            </div>
                        </button>
                    </div>
                    <b-collapse id="collapse">
                        <div class="news-expand">
                            <router-link
                                :to="{
                                    name: 'News',
                                    query: {
                                        loai: 'noi-bat',
                                    },
                                }"
                                ><div class="nav-item news">
                                    {{ $t('navbar.outstanding') }}
                                </div></router-link
                            >
                            <div class="dropdown-divider"></div>
                            <router-link
                                :to="{
                                    name: 'News',
                                    query: {
                                        loai: 'moi',
                                    },
                                }"
                                ><div class="nav-item news">
                                    {{ $t('navbar.newest') }}
                                </div></router-link
                            >
                        </div>
                    </b-collapse>
                    <b-nav-item-dropdown
                        :text="$t('navbar.career')"
                        class="dropdown-sidebar"
                    >
                        <b-dropdown-item
                            ><router-link
                                :to="{
                                    name: 'Careers',
                                    params: {
                                        type: 'careers',
                                    },
                                }"
                                ><div class="nav-item careers">
                                    {{ $t('navbar.opportunity') }}
                                </div></router-link
                            ></b-dropdown-item
                        >
                        <div class="dropdown-divider"></div>
                        <b-dropdown-item
                            ><router-link
                                :to="{
                                    name: 'LifeofPi',
                                    params: {
                                        type: 'lifeofpi',
                                    },
                                }"
                                ><div class="nav-item lop">
                                    Life of Pi
                                </div></router-link
                            ></b-dropdown-item
                        >
                    </b-nav-item-dropdown>
                    <router-link
                        :to="{
                            name: 'Contact',
                            params: {
                                type: 'contact',
                            },
                        }"
                        class="nav-link-item"
                    >
                        <div class="nav-item contact">
                            {{ $t('navbar.contact') }}
                        </div>
                    </router-link>
                </b-navbar-nav>
            </b-sidebar>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="nav-wrapper" v-on:click.prevent>
                    <router-link
                        :to="{
                            name: 'Home',
                            params: {
                                type: 'home',
                            },
                        }"
                        class="nav-link-item"
                    >
                        <div class="nav-item home">{{ $t('navbar.home') }}</div>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'About',
                            params: {
                                type: 'about',
                            },
                        }"
                        class="nav-link-item"
                    >
                        <div class="nav-item about">
                            {{ $t('navbar.about') }}
                        </div>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'Brand',
                            params: {
                                type: 'brand',
                            },
                        }"
                        class="nav-link-item"
                    >
                        <div class="nav-item brand">
                            {{ $t('navbar.brand') }}
                        </div>
                    </router-link>
                    <router-link
                        :to="{
                            name: 'Project',
                            params: {
                                type: 'project',
                            },
                        }"
                        class="nav-link-item"
                    >
                        <div class="nav-item project">
                            {{ $t('navbar.project') }}
                        </div>
                    </router-link>
                    <div class="nav-link-item news">
                        <router-link
                            :to="{
                                name: 'News',
                                params: {
                                    type: 'news',
                                },
                            }"
                            class="nav-item-news"
                        >
                            <span>{{ $t('navbar.news') }}</span>
                        </router-link>
                        <ul>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'News',
                                        query: {
                                            loai: 'noi-bat',
                                        },
                                    }"
                                    >{{ $t('navbar.outstanding') }}</router-link
                                >
                            </li>
                            <li>
                                <router-link
                                    :to="{
                                        name: 'News',
                                        query: {
                                            loai: 'moi',
                                        },
                                    }"
                                    >{{ $t('navbar.newest') }}</router-link
                                >
                            </li>
                        </ul>
                    </div>
                    <b-nav-item-dropdown
                        :text="$t('navbar.career')"
                        class="nav-link-item"
                    >
                        <b-dropdown-item
                            ><router-link
                                :to="{
                                    name: 'Careers',
                                    params: {
                                        type: 'careers',
                                    },
                                }"
                                ><div class="nav-item careers">
                                    {{ $t('navbar.opportunity') }}
                                </div></router-link
                            ></b-dropdown-item
                        >
                        <div class="dropdown-divider"></div>
                        <b-dropdown-item
                            ><router-link
                                :to="{
                                    name: 'LifeofPi',
                                    params: {
                                        type: 'lifeofpi',
                                    },
                                }"
                                ><div class="nav-item lop">
                                    Life of Pi
                                </div></router-link
                            ></b-dropdown-item
                        >
                    </b-nav-item-dropdown>
                    <router-link
                        :to="{
                            name: 'Contact',
                            params: {
                                type: 'contact',
                            },
                        }"
                        class="nav-link-item"
                    >
                        <div class="nav-item contact">
                            {{ $t('navbar.contact') }}
                        </div>
                    </router-link>
                </b-navbar-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-dropdown
                        class="btn"
                        variant="white"
                        right
                        toggle-class="lang-item"
                    >
                        <template v-slot:button-content>
                            {{ languageActive.title }}
                        </template>
                        <b-dropdown-item
                            class="language-item"
                            v-for="(lang, i) in languages"
                            :key="`Lang${i}`"
                            :value="lang"
                            @click="setLanguage(lang.language, lang.title)"
                            :class="{
                                active:
                                    languageActive.language === lang.language,
                            }"
                        >
                            <span class="align-middle">{{ lang.title }}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </header>
</template>

<script>
import i18n from '../../i18n';

export default {
    name: 'HeaderNav',
    props: {
        msg: String,
    },
    data() {
        return {
            active: this.$route.path.slice(1, this.$route.fullPath.length),
            sticky: false,
            lan: i18n.locale,
            value: null,
            text: null,
        };
    },
    mounted() {},
    computed: {
        languages() {
            return [
                {
                    language: 'vi',
                    title: this.$t('navbar.viet'),
                },
                {
                    language: 'en',
                    title: this.$t('navbar.eng'),
                },
            ];
        },
        languageActive() {
            const value = this.languages.find(
                (x) => x.language === i18n.locale,
            );
            if (!value) {
                this.setLanguage(
                    this.languages[0].language,
                    this.languages[0].title,
                );
                return this.languages[0];
            }
            return value;
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            if (window.scrollY > 40) this.sticky = true;
            else this.sticky = false;
        },
        handleCollapse() {
            this.activeCollapse = !this.activeCollapse;
            this.$refs.iconCollapse.classList.toggle('active');
        },
        setLanguage(locale, country) {
            this.lan = locale;
            this.text = country;
            i18n.locale = locale;
        },
    },
};
</script>

<style lang="scss">
#top-bar {
    position: relative;
    height: 80px;
    animation: scaleHeightOut 0.5s ease-in-out forwards;
    z-index: 99;
    border-bottom: 1px solid #fff;
    outline: none;
    .navlogo {
        max-width: fit-content;
    }
    .navbar {
        height: calc(100% + 1px);
        @media screen and (max-width: 992px) {
            padding: 0 40px;
        }
        .b-sidebar-header {
            button {
                color: #fff !important;
                font-size: 2rem;
            }
        }
        .b-sidebar-outer {
            #sidebar {
                background-color: #000093 !important;
                @media screen and (min-width: 992px) {
                    display: none;
                }
                @media screen and (min-width: 1200px) {
                    position: unset;
                }
                a {
                    color: #fff;
                    margin: 0 auto;
                    padding: 10px 0;
                    .nav-item {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .dropdown-menu {
        top: 135%;
        background-color: rgba(0, 0, 147, 0.8);
        border: 1px solid black;
        padding: 5px;
        .dropdown-item {
            background-color: transparent;
        }
        li {
            a {
                color: #fff;
            }
        }
    }
    &.sticky {
        height: 60px;
        position: fixed;
        left: 0;
        right: 0;
        animation: scaleHeightIn 0.5s ease-in-out forwards;
        background-color: #000093;
    }
}

.btn {
    width: 150px;
    @media screen and (max-width: 1200px) {
        width: 100px;
    }
    .dropdown-toggle {
        color: white;
        &:hover {
            color: white;
            font-weight: bold;
        }
        @media screen and (max-width: 1200px) {
            font-size: 0.75rem;
        }
    }
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
    width: 1.2rem;
    height: 1.5rem;
}

.custom-toggler.navbar-toggler {
    border-color: rgb(255, 255, 255) !important;
}

.navbar.bg-nav {
    padding: 0 6rem 0 6rem;
    @media screen and (max-width: 400px) {
        padding: 0 3.5rem 0 3.5rem;
    }
}

.bg-nav {
    box-shadow: rgba(50, 50, 93, 0.25) 2px 4px 5px -1px;
    background-color: #000093;
    padding: 20px 0;
}

.navbar-brand {
    height: 100%;
    width: 50px;
    img {
        height: 100%;
        padding: 10px 0;
    }
}

.nav-wrapper {
    margin-left: auto !important;
    @media screen and (max-width: 1220px) {
        margin-left: 20px !important;
    }
}
.nav-link-item {
    margin-right: 32px;
    color: #ffffff;
    @media screen and (max-width: 1220px) {
        margin-right: 20px;
    }
    &:hover {
        text-decoration: none;
        font-weight: bolder;
    }
    &.news {
        position: relative;
        a {
            color: #fff;
        }
        ul {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 220%;
            list-style: none;
            padding: 0;
            top: 160%;
            display: none;
            flex-direction: column;
            transition: all 0.5s ease;
            background-color: rgba(0, 0, 147, 0.8);
            li {
                cursor: pointer;
                font-size: 1.2rem;
                padding: 5px 10px;
                @media screen and (max-width: 1268px) {
                    font-size: 0.9rem;
                }
                @media (max-width: 1058px) {
                    font-size: 0.8rem;
                }
                &:hover {
                    background-color: rgba(255, 255, 255, 0.25);
                }
            }
            &:after {
                content: '';
                width: 85%;
                height: 35%;
                left: 50%;
                transform: translateX(-50%);
                position: absolute;
                top: -30%;
                background: transparent;
            }
        }
        &:hover {
            ul {
                display: flex;
            }
        }
    }
    .nav-item {
        font-size: 19px;
        color: rgb(255, 255, 255);
        @media screen and (max-width: 1268px) {
            font-size: 0.9rem;
        }
        @media (max-width: 1058px) {
            font-size: 0.8rem;
        }
        &:last-child {
            margin-right: 0;
        }
        &.careers,
        &.lop {
            padding: 0 10px;
        }
    }
    &.active {
        .nav-item {
            border-bottom: 3px solid #fefefe;
        }
    }
    a {
        padding: 0;
        text-decoration: none !important;
        @media (max-width: 1268px) {
            display: flex;
            align-items: center;
        }
        span {
            font-size: 1.2rem;
            @media screen and (max-width: 1268px) {
                font-size: 0.9rem;
            }
            @media (max-width: 1062px) {
                font-size: 0.8rem;
            }
        }
    }
    .dropdown-menu {
        /* background-color: #000093; */
        z-index: 999;
        padding: 10px 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        top: 45px;
        .dropdown-item {
            font-size: 0.8rem;
            &:hover {
                /* background-color: rgb(59, 59, 231); */
                max-width: 100% !important;
            }
        }
    }
}

.navlogo-sidebar {
    display: flex;
    justify-content: center;
    align-content: center;
    .logo-sidebar {
        height: 60px;
        margin-bottom: 1rem;
    }
}
.dropdown-sidebar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    a {
        padding: 0;
        text-decoration: none !important;
        span {
            font-size: 1.2rem;
            @media screen and (max-width: 1268px) {
                font-size: 0.9rem;
            }
            @media (max-width: 1062px) {
                font-size: 0.8rem;
            }
        }
    }
    padding: 0 10px;
    .dropdown-menu {
        /* background-color: rgba(40, 40, 245, 0.897); */
        z-index: 9;
        border-radius: 10px;
        padding: 0 5px;
        .dropdown-divider {
            @media screen and (max-width: 991px) {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: center;
                height: 1px;
                border: none;
                &:after {
                    content: '';
                    position: absolute;
                    width: 50%;
                    height: 1px;
                    background: #fff;
                }
            }
        }
        .dropdown-item {
            display: flex;
            justify-content: center;
            font-size: 0.8rem;
            .nav-item {
                padding: 0;
            }
            &:hover {
                /* background-color: rgb(59, 59, 231); */
                max-width: 100% !important;
            }
        }
    }
}

.dropdown.btn {
    border: 2px solid #ffffff;
    border-radius: 10px;
    padding: 0 !important;
    font-size: 15px;
    font-style: normal;
    padding: 0;
    .dropdown-menu {
        li {
            font-size: 15px;
        }
    }
    &:hover {
        outline: none;
    }
    .dropdown-item {
        outline: none;
        border: none;
        a {
            outline: none;
            font-size: 15px;
        }
    }
    em {
        font-style: normal;
        font-size: 15px;
        color: rgb(255, 255, 255);
    }
}

.navbar-light .navbar-nav .nav-link {
    color: rgb(255, 255, 255) !important;
}

.sub-news-nav {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    button {
        position: absolute;
        right: 35%;
        top: 50%;
        font-size: 14px;
        transform: translateY(-50%);
        border: none;
        background: transparent;
        color: white;
        display: flex;
        padding: 5px 10px;
        .icon-collapse {
            display: flex;
            transition: all 0.5s ease;
            &.active {
                transform: rotateZ(-90deg);
            }
        }
    }
}
.news-expand {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.151);
    width: 90%;
    margin: 0 auto;
    padding: 0 5px;
    margin-top: 0;
    background-color: rgba(0, 0, 147, 0.8);
    font-size: 1.2rem;
    @media screen and (max-width: 1268px) {
        font-size: 0.9rem;
    }
    @media (max-width: 1062px) {
        font-size: 0.8rem;
    }
}

@keyframes scaleHeightIn {
    0% {
        height: 90px;
        /* top: -10px; */
    }
    100% {
        height: 70px;
        /* top: 0; */
    }
}
@keyframes scaleHeightOut {
    0% {
        height: 60px;
        /* top: 20px; */
    }
    100% {
        height: 70px;
        /* top: 0; */
    }
}
</style>
