
const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        careerLists: null,
        careerDetail: null,
        careerPosition: null,
        careerAddress: null,
        uploadCV: null,
        careerSearch: null,
        registerEmail: null,
    }),
    getters: {
        CareerLists: (state) => state.careerLists,
        CareerDetail: (state) => state.careerDetail,
        CareerPosition: (state) => state.careerPosition,
        CareerAddress: (state) => state.careerAddress,
        UploadCV: (state) => state.uploadCV,
    },
    actions: {
        req_getCareerLists({ commit }) {
            axios.get('career-list').then((res) => {
                if (res) {
                    commit('GET_CAREER_LIST_SUCCESS', res);
                }
            });
        },
        req_getCareerDetail({ commit }, input) {
            axios.get(`career/detail?slug=${input}`).then((res) => {
                if (res) {
                    commit('GET_CAREER_DETAIL_SUCCESS', res);
                }
            });
        },
        req_getCareerPosition({ commit }) {
            axios.get(`career`).then((res) => {
                if (res) {
                    commit('GET_CAREER_POSITION_SUCCESS', res);
                }
            });
        },
        req_getCareerSearch({ commit }, { keyword, office, position }) {
            let path = '';
            if (!position && !office && !keyword) {
                path = '';
            } else {
                const res1 =
                    position && position !== 'all' ? `id=${position}` : '';
                const res2 =
                    office && office !== 'all' ? `address=${office}` : '';
                const res3 = keyword ? `career_detail_title=${keyword}` : '';
                path = `/career?${res1}&${res2}&${res3}`;
            }
            axios.get(`career-list${path}`).then((res) => {
                if (res) {
                    commit('GET_CAREER_SEARCH_SUCCESS', res);
                }
            });
        },
        req_getCareerAddress({ commit }) {
            axios.get(`career-address`).then((res) => {
                if (res) {
                    commit('GET_CAREER_ADDRESS_SUCCESS', res);
                }
            });
        },
        req_postUploadCV({ commit }, input) {
            const formData = new FormData();
            formData.append('email', input.email);
            formData.append('cv', input.cv);
            formData.append('cv_career_id', input.cv_career_id);
            axios
                .post('postcv', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    if (res !== null && res !== undefined) {
                        commit('SEND_CV_SUCCESS', res);
                    }
                });
        },
        req_postRegisterEmail({ commit }, input) {
            const formData = new FormData();
            formData.append('email', input.email);
            formData.append('name', input.name);
            axios
                .post('register-email', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    if (res !== null && res !== undefined) {
                        commit('REGISTER_EMAIL_SUCCESS', res);
                    }
                });
        },
    },
    mutations: {
        GET_CAREER_LIST_SUCCESS(state, data) {
            state.careerLists = data;
        },
        GET_CAREER_DETAIL_SUCCESS(state, data) {
            state.careerDetail = data;
        },
        GET_CAREER_POSITION_SUCCESS(state, data) {
            state.careerPosition = data;
        },
        GET_CAREER_ADDRESS_SUCCESS(state, data) {
            state.careerAddress = data;
        },
        SEND_CV_SUCCESS(state, data) {
            state.uploadCV = data;
        },
        GET_CAREER_SEARCH_SUCCESS(state, data) {
            state.careerLists = data;
        },
        REGISTER_EMAIL_SUCCESS(state, data) {
            state.registerEmail = data;
        },
    },
};
