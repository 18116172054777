import layout from './layout/Master.vue';

export default [
    {
        path: '/',
        component: layout,
        name: 'Layout',
        redirect: { name: 'Home', params: { type: 'home' } },
        children: [
            {
                path: 'home',
                name: 'Home',
                component: () => import('./views/Home.vue'),
            },
            {
                path: 'about',
                name: 'About',
                component: () => import('./views/AboutUs.vue'),
            },
            {
                path: 'project',
                name: 'Project',
                component: () => import('./views/Project.vue'),
            },
            {
                path: 'brand',
                name: 'Brand',
                component: () => import('./views/Brands.vue'),
            },
            {
                path: 'lifeofpi',
                name: 'LifeofPi',
                component: () => import('./views/LifeofPi.vue'),
            },
            {
                path: 'news',
                name: 'News',
                component: () => import('./views/News.vue'),
            },
            {
                path: 'news/:slug',
                name: 'NewsDetails',
                component: () => import('@/components/Blog/NewsDetails.vue'),
            },
            {
                path: 'careers',
                name: 'Careers',
                component: () => import('./views/Careers.vue'),
            },
            {
                path: '/careers/:slug',
                name: 'DetailCareer',
                component: () => import('@/components/Career/DetailCareer.vue'),
            },
            {
                path: 'contact',
                name: 'Contact',
                component: () => import('./views/Contact.vue'),
            },
        ],
    },
];
