<template>
    <div id="preloader" v-if="loadingStatus">
        <div id="status">
            <b-spinner
                class="spinner-load"
                variant="primary"
                label="Spinner"
                type="grow"
            ></b-spinner>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'LoaderComponent',
    computed: {
        ...mapState(['loadingStatus']),
    },
};
</script>

<style lang="scss" scoped>
// Loader
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7)
    );
    z-index: 9999999;
    #status {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .spinner-load {
            width: 4rem;
            height: 4rem;
        }
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}
</style>
