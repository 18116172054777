/* eslint-disable import/no-unresolved */
<template>
    <div id="PiSolution">
        <Header />
        <Loader />
        <main class="content"><router-view></router-view></main>
        <BackToTop />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import Loader from '@/components/shared/Loader.vue';
import BackToTop from '@/components/shared/BackToTop.vue';

import { mapMutations } from 'vuex';

export default {
    name: 'MasterLayout',
    components: { Header, Footer, Loader, BackToTop },
    mounted() {
        const { type } = this.$route.params;
        if (type) {
            this.propertiesActive = type;
        } else {
            this.propertiesActive = this.$route.name;
        }
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
    },
    watch: {
        $route(to) {
            const { type } = to.params;
            if (type) {
                this.propertiesActive = type;
            } else {
                this.propertiesActive = this.$route.name;
            }
        },
    },
};
</script>

<style lang="scss">
main.content {
    min-height: calc(100vh - 90px);
    height: 100%;
}
</style>
