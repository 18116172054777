<template>
    <div class="back-top" v-if="show" @click="onClickToTop">
        <div class="back-top-img">
            <b-img
                class="w-100 h-100"
                :src="require('@/assets/images/icon/top.png')"
            ></b-img>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BackToTop',
    data() {
        return {
            show: false,
        };
    },
    created() {
        window.addEventListener('scroll', this.onScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        onScroll() {
            if (window.scrollY >= window.innerHeight) {
                this.show = true;
            } else this.show = false;
        },
        onClickToTop() {
            window.scrollTo(0, 0);
        },
    },
};
</script>

<style lang="scss" scoped>
.back-top {
    position: fixed;
    cursor: pointer;
    right: 0;
    bottom: 0;
    margin: 50px;
    height: 50px;
    width: 50px;
    z-index: 99;
}
</style>
